.file-uploader {
  position: fixed;
  //flex-direction: column;
  align-items: center;
  width: 300px;
  height: 300px;
  border: 2px dashed #4282fe;
  background-color: #F7FBFD;
  border-radius: 8px;
}

.drop-active {
  background-color: #B8E2F2;
}

.thead-left {
  text-align:left;
  padding-bottom: 16px;
}

.thead-center {
  text-align:center;
  padding-bottom: 16px;
}

.tcell-left {
  text-align:left;
}